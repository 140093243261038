/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Mon Feb 21 2022
 */
import { fileRequest } from '@/api'
import { isImg, isPdf, loadScript } from './lib'
import store from '@/store'

export async function loadImgAsBlobUri (id, size, type) {
  const response = await fileRequest.download({ id, size })
  return window.URL.createObjectURL(new Blob([response], { type: type }))
}

export async function loadImgAsDataUri (id, size) {
  const response = await fileRequest.download({ id, size })
  return await new Promise(resolve => {
    let reader = new FileReader()
    reader.readAsDataURL(response)
    reader.onload = () => resolve(reader.result)
  })
}

export async function loadPdfCanvas (id) {
  await loadScript('/static/pdfjs-2.12.313-dist/build/pdf.js')
  const pdfjsBase = '/static/pdfjs-2.12.313-dist';
  window.pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsBase + '/build/pdf.worker.js';

  const response = await fileRequest.download({ id })
  const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf ' }))
  const res = await window.pdfjsLib.getDocument({
    url: url,
    cMapUrl: pdfjsBase + '/web/cmaps/',
    cMapPacked: true
  }).promise

  const canvasList = []
  for (let i = 1; i <= res.numPages && i <= 10; i++) {
    const page = await res.getPage(i)
    const viewport = page.getViewport({ scale: 1.5 })

    const outputScale = window.devicePixelRatio || 1;

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    canvas.width = viewport.width * outputScale
    canvas.height = viewport.height * outputScale
    canvas.style.width = viewport.width + 'px'
    canvas.style.height = viewport.height + 'px'

    var transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

    await page.render({ canvasContext: context, transform: transform, viewport: viewport }).promise;
    canvasList.push(canvas)
  }

  return canvasList
}

export async function loadPdfAsBlobUrl (id) {
  try {
    const res = await loadPdfCanvas(id)
    return await Promise.all(res.map(v => {
      return new Promise(resolve => {
        v.toBlob(blob => {
          resolve(window.URL.createObjectURL(blob))
        }, 'image/png')
      })
    }))
  } catch (error) {
    return []
  }
}

export async function loadPdfAsDataUri (id) {
  try {
    const res = await loadPdfCanvas(id)
    return await Promise.all(res.map(v => {
      return v.toDataURL('image/png')
    }))
  } catch (error) {
    return []
  }
}

export function loadResourcesByCache({ file, size, type }) {
  type = type || 'blob'
  return new Promise(resolve => {
    if (isImg(file.name)) {
      if (type === 'uri') {
        loadImgAsDataUri(file.id, size).then(res => {
          resolve(res)
        }).catch((err) => {
          console.log(err)
          resolve(null)
        })
      } else {
        loadImgAsBlobUri(file.id, size, 'image/' + file.name.split('.').pop()).then(res => {
          resolve(res)
        }).catch((err) => {
          console.log(err)
          resolve(null)
        })
      }
    } else if (isPdf(file.name)) {
      if (type === 'uri') {
        loadPdfAsDataUri(file.id).then(res => {
          resolve(res)
        }).catch((err) => {
          console.log(err)
          resolve(null)
        })
      } else {
        loadPdfAsBlobUrl(file.id).then(res => {
          resolve(res)
        }).catch((err) => {
          console.log(err)
          resolve(null)
        })
      }
    } else {
      resolve(null)
    }
  })
}

export async function loadImgs (data, getName, noCover = false) {
  await store.dispatch('loadFileToken')
  const nameMap = {}
  const ids = data.map(item => {
    const fileIds = item.fileIds ? String(item.fileIds).split(',') : []
    fileIds.forEach(v => nameMap[v] = typeof getName === 'function' ? getName(item) : getName)
    return fileIds
  }).flat()

  if (!ids || (Array.isArray(ids) && !ids.length)) {
    return noCover ? [] : {
      covers: [],
      previews: []
    }
  } else {
    const files = await fileRequest.getFileData({ ids: Array.isArray(ids) ? ids.join(',') : ids })
    const promise = files.map(v => {
      return {
        label: nameMap[v.id],
        name: v.name,
        id: v.id,
      }
    }).map(async file => {
      // file.src = await loadResourcesByCache(noCover ? { file, type: 'blob' } : { file, size: 'small', type: 'blob' })
      file.src = store.getters.fileUrl({ id: file.id, size: noCover ? undefined : 'small', mode: 'preview' })
      return file
    })
    
    if (noCover) {
      return await Promise.all(promise)
    } else {
      return {
        covers: await Promise.all(promise),
        previews: () => {
          const promise = files.map(v => {
            return {
              label: nameMap[v.id],
              name: v.name,
              id: v.id,
            }
          }).map(async file => {
            // file.src = await loadResourcesByCache({ file, type: 'blob' })
            file.src = store.getters.fileUrl({ id: file.id, mode: 'preview' })
            return file
          })
          return Promise.all(promise)
        }
      }
    }
  }
}